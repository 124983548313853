import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/web/src/app/provider.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/web/src/components/_global/AuthHandler/AuthHandler.AppRouter.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/node_modules/@vercel/speed-insights/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/node_modules/react-responsive-modal/styles.css");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--manrope-font\"}],\"variableName\":\"manrope\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/apps/web/src/styles/globals.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/Icon.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/global/Footer/Footer.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/global/Navbar/Navbar.module.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/address.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/alert-circle.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/alert-triangle.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/arbitrum.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/arrow-down.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/arrow-left-right.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/arrow-left.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/arrow-right.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/arrow-up-right.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/arrow-up.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/avalanche.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/award.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/bnb.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/bold.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/book-open.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/box.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/braces.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/briefcase.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/cable.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/cake.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/calendar-check.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/candlestick-chart.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/check-check.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/check-circle-2.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/check-circle.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/check.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/chevron-down.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/chevron-up.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/chevrons-right.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/code-2.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/code.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/coinbase.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/coins.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/collectibles.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/copy.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/corner-up-left.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/corner-up-right.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/disconnect.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/discord.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/dollar-sign.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/ethereum.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/etherscan-logo-circle.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/eye-off.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/eye.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/fantom.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/feather.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/file-badge.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/file-code.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/file-question.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/file.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/forward.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/gamepad.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/github.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/globe-2.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/globe.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/hash.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/heart-handshake.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/help-circle.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/hexagon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/image-plus.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/info.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/italic.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/landmark.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/layers.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/ledger.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/link-2.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/link.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/list-ordered.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/list.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/lock.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/log-in.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/log-out.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/logo-full-black.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/logo-mark-black.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/logo.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/mail.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/menu.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/metamask.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/minus-circle.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/optimism.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/piggy-bank.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/plug.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/plus.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/polygon-purple.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/polygon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/quote.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/radar.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/repeat.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/search.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/server-off.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/server.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/settings.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/shield-check.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/shield-x.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/shield.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/slash.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/sparkles.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/star.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/strikethrough.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/tags.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/telegram.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/thumbs-up.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/token.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/toy-brick.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/twitter.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/usb.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/user.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/users.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/wallet.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/walletconnect.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/wrench.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/x-circle.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/x.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/youtube.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/monorepo/monorepo/packages/ui/src/components/standalone/Icon/icons/zksync.svg")