'use client';

import { UserAuthenticationState, UserType } from '@trustblock/types/user.types';
import useAuth from '@trustblock/web/hooks/auth/useAuth';
import { useEffect, useState } from 'react';
import Button from '@trustblock/components/standalone/Button/Button';
import { usePathname, useRouter } from 'next/navigation';
import { truncateString } from '@trustblock/helpers/formatters';
import styles from './AuthHandler.module.scss';

export default function AuthHandler() {
  const { isAuthenticating, login, logout, user, userAuthenticationState } = useAuth({
    isInAutomaticMode: true
  });
  const router = useRouter();
  const pathname = usePathname();
  const [isBeingRedirected, setIsBeingRedirected] = useState(false);
  const shouldLoginBeDisabled = isAuthenticating;

  useEffect(() => {
    if (
      userAuthenticationState === UserAuthenticationState.ConnectedWithoutUser &&
      !isBeingRedirected &&
      !pathname?.includes('/user/onboard')
    ) {
      setIsBeingRedirected(true);
      void router.push('/user/onboard');
    }
  }, [userAuthenticationState, pathname, router, isBeingRedirected]);

  const isFullySignedIn = userAuthenticationState === UserAuthenticationState.SignedIn && !!user;

  return (
    <div data-testid="auth-handler-container" className={styles.tbAuthHandlerContainer}>
      {userAuthenticationState !== UserAuthenticationState.Visitor && (
        <div className={styles.tbAuthHandlerConnectedButtons}>
          <Button
            mode="primary"
            icon="User"
            type="link"
            loading={isAuthenticating || !isFullySignedIn}
            href={`/${user?.userType === UserType.Auditor ? 'auditor' : 'integrator'}/${user?.slug}`}
            data-testid="auth-handler-profile-button"
          >
            {isFullySignedIn ? truncateString(user.name, 15) : 'Onboarding'}
          </Button>

          <Button
            data-testid="auth-handler-logout-button"
            onClick={() => {
              void logout();
            }}
            loading={isAuthenticating}
            mode="secondary"
            icon="Logout"
            onlyIcon
          />
        </div>
      )}

      {userAuthenticationState === UserAuthenticationState.Visitor && (
        <Button
          data-testid="auth-handler-login-button"
          onClick={login}
          loading={isAuthenticating}
          disabled={shouldLoginBeDisabled}
          fill
          mode="primary"
          icon="Login"
        >
          Connect
        </Button>
      )}
    </div>
  );
}
