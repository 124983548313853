'use client';

import environment from '@trustblock/constants/environment.constants';
import { PostHogProvider } from 'posthog-js/react';
import { PrivyProvider } from '@privy-io/react-auth';
import { initPosthog } from '../helpers/posthog';

if (typeof window !== 'undefined') {
  initPosthog();
}

export function Provider({ children }: { children: React.ReactNode }) {
  return (
    <PostHogProvider>
      <PrivyProvider
        appId={environment.PRIVY_APP_ID}
        config={{
          appearance: {
            logo: ''
          }
        }}
      >
        {children}
      </PrivyProvider>
    </PostHogProvider>
  );
}
